import Vue from 'vue'

const MsgService = {
  allMsg () {
    return Vue.http.get('msg/index')
  },
  allAct () {
    return Vue.http.get('msg/act')
  },
  create (model = null) {
    const endpoint = 'msg/create'

    if (model === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, model)
  },
  // 编辑
  edit (id, model = null) {
    if (model === null) {
      return Vue.http.get('msg/update', { id: id })
    }

    return Vue.http.put('msg/update', model, { id: id })
  },
  del (param) {
    return Vue.http.delete('msg/delete', param)
  }
}

export default MsgService
