<template>
  <div>
    <el-table
      v-loading="loading"
      height="67vh"
      :stripe="true"
      :data="lists">
      <el-table-column
        width="80"
        label="序号"
        prop="id"
      >
      </el-table-column>
      <el-table-column
        width="120"
        label="通知主题"
        prop="title"
      >
      </el-table-column>
      <el-table-column
        label="发送时间"
        prop="sendTime"
      >
      </el-table-column>
      <el-table-column
        label="发送方式"
        prop="sendType"
      >
      </el-table-column>
      <el-table-column
        label="状态"
        width="160"
        prop="status"
      >
        <template slot-scope="scope">
          <p v-if="scope.row.status == 0">关闭</p>
          <p v-if="scope.row.status == 1" style="color:#409EFF;">开启</p>
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        width="190"
        label="操作"
      >
        <template slot-scope="scope">
          <el-dropdown
            trigger="click"
          >
            <el-button
              plain
              size="mini"
              type="primary"
            >
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="$can('admin/msg/update')"
                @click.native="handleModelEdit(scope.row)"
              >
                编辑
              </el-dropdown-item>
              <el-dropdown-item
                v-if="$can('admin/msg/delete')"
                @click.native="handleModelDelete(scope.row)"
              >
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="shop-i-bottom">
      <el-button type="primary" @click="AddMsg">新增消息通知</el-button>

      <page
        class="shop-page"
        :pageSize="limit"
        :count="count"
        @change="pageChange"
        @changeSize="changeSize"
      >
      </page>
    </div>
  </div>
</template>

<script>
import MsgService from '@admin/services/MsgService'
// import flatry from '@admin/utils/flatry'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  name: 'MsgModel',
  data () {
    return {
      function: function (param) {
        return MsgService.allMsg(param)
      },
      delete: function (param) {
        return MsgService.del(param)
      }
    }
  },

  components: {
    Page
  },
  mixins: [pagination],
  methods: {
    AddMsg () {
      this.$router.replace({ path: '/msg-temp/create' })
    },
    handleModelEdit (model) {
      this.$router.push({ path: '/msg-temp/update', query: { id: model.id } })
    }
  }

}

</script>
<style lang='scss' scoped>
.shop-i-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .shop-page {
        float: right;
        margin: 0;
    }
}

</style>
